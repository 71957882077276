import React from "react";
import Base from "./Base";
import TopBanner from "./fragments/TopBanner";

export default class Home extends Base {

  render() {
    return (
      <div>
        <TopBanner/>
      </div>
    );
  }
}
