import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

function ConsentToCookies() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept and Continue"
      cookieName="myCookie"
      expires={150}
      style={{ background: "#071C4BCC", borderTop: "1px solid #273C6B " }}
      buttonStyle={{
        color: "white",
        backgroundColor: "#071C4B",
        fontSize: "13px",
        borderRadius: 8,
        fontWeight: 500,
        border: "1px solid a9f3e120",
      }}
      contentStyle={{ color: "#ffffff" }}
    >
      <span>
        We use cookie to improve your experience on our site. By using our
        website you consent to all cookies in accordance with our{" "}
        <Link to={"/terms"}>Terms of Service</Link> and{" "}
        <Link to="/privacy">Privacy Policy</Link>.
      </span>
    </CookieConsent>
  );
}

export default ConsentToCookies;
