import React from "react";
import { isMobileOnly } from "react-device-detect";

const ResponsiveImage = ({ image, image2x, alt = "Image", link }) => {
  const img =
    isMobileOnly && !!image2x ? (
      <img
        className={"responsive-image"}
        src={image}
        srcSet={`${image} 1x, ${image2x} 2x`}
        alt={alt}
        sizes="(max-width: 600px) 100vw, 600px"
      />
    ) : (
      <img className={"responsive-image"} src={image2x || image} alt={alt} />
    );
  return link ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {img}
    </a>
  ) : (
    img
  );
};

export default ResponsiveImage;
