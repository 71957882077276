import React from "react";
import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
// const Oups = require("./components/Oups");
const App = require("./components/App");
import "../client/assets/css/style.css";

// ReactDOM.render(<Oups />, document.getElementById("root"));
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
