const isDev =
  typeof window === "undefined"
    ? process.env.NODE_ENV === "development"
    : /(local|stage|superpowerlabs)/.test(window.location.hostname);

const config = {
  isDev,
  socialLinks: {
    discord: "https://discord.gg/6Z2Y4Z",
    twitter: "https://twitter.com/superpowerlabs",
    instagram: "https://www.instagram.com/superpowerlabs/",
    youtube: "https://www.youtube.com/channel/UC0ZQZ1X8Z1Z2Z3Z4Z5Z6Z7",
    tiktok: "https://www.tiktok.com/@superpowerlabs",
  },
  galaxyStoreLink:
    "https://galaxystore.samsung.com/detail/io.superpower.samsung.bytecity",
};

module.exports = config;
