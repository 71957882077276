import React from "react";
import { Modal, Button } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ethers } from "ethers";
import ls from "local-storage";
import Common from "./Common";
import clientApi from "../utils/ClientApi";
import config from "../config";
import Home from "./Home";
import Error404 from "./Error404";
import ConsentToCookies from "./lib/ConsentToCookies";
import { minimalisticHash } from "../utils";


class App extends Common {
  constructor(props) {
    super(props);

    let localStore = JSON.parse(ls("localStore") || "{}");
    let pathhash = ethers.utils.id(window.location.pathname);
    if (/(stage|superpowerlabs)/.test(window.location.hostname)) {
      const authorized = localStorage.getItem("authorized");
      if (!authorized) {
        const hash = minimalisticHash(location.hash || "");
        if (hash === 1932582580) {
          localStorage.setItem("authorized", new Date().toISOString());
        } else {
          window.location = "https://byte.city";
          return <div />;
        }
      }
    }

    this.state = {
      Store: Object.assign(
        {
          content: {},
          editing: {},
          temp: {},
          menuVisibility: false,
          config,
          width: this.getWidth(),
          pathname: window.location.pathname,
        },
        localStore
      ),
      pathhash,
    };

    this.bindMany([
      "handleClose",
      "handleShow",
      "setStore",
      "updateDimensions",
      "showModal",
      "setWallet",
      "connect",
      "handleChange",
    ]);
  }

  getWidth() {
    return window.innerWidth;
  }

  updateDimensions() {
    this.setStore({
      width: this.getWidth(),
    });
  }

  componentDidMount() {
    const mediaQuery = matchMedia("(orientation: portrait)");
    mediaQuery.addEventListener("change", this.handleChange);
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    const mediaQuery = matchMedia("(orientation: portrait)");
    mediaQuery.removeEventListener("change", this.handleChange);
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  handleChange(e) {
    window.location.reload();
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  async connect() {
    if (typeof window.ethereum !== "undefined") {
      let eth = window.ethereum;
      if (await eth.request({ method: "eth_requestAccounts" })) {
        eth.on("accountsChanged", () => window.location.reload());
        eth.on("chainChanged", () => window.location.reload());
        eth.on("disconnect", () => window.location.reload());
        this.setWallet(eth, "metamask");
      }
    }
  }

  // async switchTo(chainId, chainName, symbol, rpcUrls) {
  //   if (window.clover !== undefined) {
  //     const provider = window.clover;
  //     try {
  //       await provider.request({
  //         method: "wallet_addEthereumChain",
  //         params: [
  //           {
  //             chainId,
  //             chainName,
  //             nativeCurrency: {
  //               symbol,
  //               decimals: 18,
  //             },
  //             rpcUrls: [rpcUrls],
  //           },
  //         ],
  //       });
  //       return true;
  //     } catch (error) {
  //       console.error(error);
  //       return false;
  //     }
  //   }
  // }

  async setWallet(eth, connectedWith) {
    try {
      const provider = new ethers.providers.Web3Provider(eth);
      const signer = provider.getSigner();
      const chainId = (await provider.getNetwork()).chainId;
      const connectedWallet = await signer.getAddress();
      let networkNotSupported = false;
      let connectedNetwork = null;
      if (config.supportedId[chainId]) {
        connectedNetwork = config.supportedId[chainId];
      } else {
        networkNotSupported = true;
      }
      this.setStore({
        provider,
        signer,
        connectedWallet,
        chainId,
        connectedNetwork,
        networkNotSupported,
      });
      this.setStore(
        {
          connectedWith,
        },
        true
      );
      clientApi.setConnectedWallet(connectedWallet, chainId);
    } catch (e) {
      console.error(e);
      // window.location.reload()
    }
  }

  showModal(modalTitle, modalBody, modalClose, secondButton, modalAction) {
    this.setStore({
      modalTitle,
      modalBody,
      modalClose,
      secondButton,
      modalAction,
      showModal: true,
    });
  }

  setStore(newProps, storeItLocally) {
    let store = this.state.Store;
    let localStore = JSON.parse(ls("localStore") || "{}");
    let saveLocalStore = false;
    for (let i in newProps) {
      if (newProps[i] === null) {
        if (storeItLocally) {
          delete localStore[i];
          saveLocalStore = true;
        }
        delete store[i];
      } else {
        if (storeItLocally) {
          localStore[i] = newProps[i];
          saveLocalStore = true;
        }
        store[i] = newProps[i];
      }
    }
    this.setState({
      Store: store,
    });
    if (saveLocalStore) {
      ls("localStore", JSON.stringify(localStore));
    }
  }

  render() {
    const Store = this.state.Store;
    return (
      <BrowserRouter>
        <main>
          <Switch>
            <Route exact path="/">
              <Home
                Store={Store}
                setStore={this.setStore}
                handleClick={this.handleClick}
              />
            </Route>
            <Route exact path="*">
              <Error404 Store={Store} setStore={this.setStore} />
            </Route>
          </Switch>
        </main>
        {/*<ConsentToCookies />*/}
        {Store.showModal ? (
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>{Store.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{Store.modalBody}</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.setStore({ showModal: false });
                }}
              >
                {Store.modalClose || "Close"}
              </Button>
              {this.state.secondButton ? (
                <Button
                  onClick={() => {
                    Store.modalAction();
                    this.setStore({ showModal: false });
                  }}
                  bsStyle="primary"
                >
                  {Store.secondButton}
                </Button>
              ) : null}
            </Modal.Footer>
          </Modal.Dialog>
        ) : null}
      </BrowserRouter>
    );
  }
}

module.exports = App;
