import React from "react";
import { isMobileOnly } from "react-device-detect";
import { isLandscape } from "../../utils";
import { Link as ScrollLink } from "react-scroll";
import { socialLinks } from "../../config";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import Base from "../Base";
import ResponsiveImage from "../lib/ResponsiveImage";

import logo from "../../assets/images/white-mini-logo.webp";
import everdragons2 from "../../assets/images/everdragons2.webp"
// import background from "../../assets/images/landing-page.webp";
// import backgroundMobile from "../../assets/images/mobile-bg.webp";

// import {
//   discord,
//   youtube,
//   tiktok,
//   instagram,
//   twitter,
// } from "../../utils/icons";

const mobile = isMobileOnly && !isLandscape();

class TopBanner extends Base {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showSocial: false,
      showEmail: false,
    };
    // this.bindMany(["handleChange", "handleSubmit", "showEmailNow"]);
  }

  // async showEmailNow() {
  //   window.scrollTo(0, 0);
  //   await this.sleep(100);
  //   this.setState({ showEmail: true });
  // }
  //
  // socialIcon(icon, alt) {
  //   const iconDataUrl = `data:image/svg+xml,${encodeURIComponent(icon)}`;
  //   return (
  //     <a href={socialLinks[alt.toLowerCase()]} className={"social-icon-top"}>
  //       <div className={"icon-link"}>
  //         <div className={"icon-image"}>
  //           <img src={iconDataUrl} alt={alt} />
  //         </div>
  //         <div className={"icon-text"}>{alt}</div>
  //       </div>
  //     </a>
  //   );
  // }
  //
  // handleChange(event) {
  //   this.setState({ email: event.target.value });
  // }
  //
  // handleSubmit(event) {
  //   event.preventDefault();
  //   const { email } = this.state;
  //   this.props.register(email);
  // }

  // partWithImage(cls, image, image2x, alt) {
  //   return (
  //     <div className={"centered " + cls}>
  //       <ResponsiveImage image={image} image2x={image2x} alt={alt} />
  //     </div>
  //   );
  // }
  // partWithText(cls, text) {
  //   return (
  //     <div className={"centered " + cls}>
  //       <p>{text}</p>
  //     </div>
  //   );
  // }

  render() {
    const { error, success, showRegister, unShowRegister } = this.props;

    return (
      <div className={"top-banner big-banner"}>
        <div className="top-banner-inner">
          <Container fluid className="">
            <Row className="d-flex align-items-begin">
              <Col xs={12}>
                <div  className={'logo'}><img src={logo}/></div>
                <div className={'everdragons2'}><img src={everdragons2} /></div>


              </Col>


            </Row>
            {/*{mobile ? (*/}
            {/*  <Row>*/}
            {/*    <Col xs={5}>*/}
            {/*      <div className={"girls"}>*/}
            {/*        <ResponsiveImage*/}
            {/*          image={girls}*/}
            {/*          // image2x={girls2x}*/}
            {/*          alt={"Dancers"}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*) : null}*/}
          </Container>
        </div>
      </div>
    );
  }
}

export default TopBanner;
