const utils = {
  toHex(val) {
    return "0x" + Number(val).toString(16);
  },

  ellipsizeAddress(address, width = 4) {
    return `${address.slice(0, width + 2)}...${address.slice(-width)}`;
  },

  isLandscape() {
    const match = window.matchMedia("(orientation: landscape)");
    return match.matches;
  },

  minimalisticHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  },
};

module.exports = utils;
